// Estonian
export default{
    form: {
        startSurveyButton: "Alustame",
        nextButton: "Järgmine",
        submitAnswerButton: "Saada vastus",
        continueButton: "Jätka",
        finishButton: "Lõpeta",
        headerPercentageCompletion: 'valmis',
        headerQuestionsCompleted: 'küsimused',
        headerDefaultErrorMessage: 'Ups! Midagi läks valesti.',
        brandingPoweredBy: 'Töötab',
        brandingPromoWhySurvey: 'Miks teha küsitlust, kui saad kasutada <b>{product}</b>?',
        brandingPromoGetStarted: 'Alusta täna!',
        submissionPending: "Voiceform saadetakse. Palun ärge sulgege brauseri akent.",
        submissionCanCloseWindow: "Teie voiceform on edukalt saadetud. Nüüd võite akna sulgeda 👍",
        mediaAttachmentOverlayCloseButton: "Sulge",
        expiredFormHeader: "Aitäh, et tulite!",
        expiredFormMessage: "Tundub, et see küsitlus on aegunud. Palun võtke ühendust voiceformi loojaga.",
        notPublishedFormHeader: "Voiceform ei ole avaldatud!",
        notPublishedFormMessage: "Tundub, et see küsitlus ei ole avaldatud. Palun võtke ühendust voiceformi loojaga.",
        notPublishedFormRetryButton: "Proovi uuesti",
        errorFormHeader: "Ups!",
        errorFormMessage: "Midagi võis valesti minna. Palun proovige hiljem uuesti.",
        errorFormRetryButton: "Proovi uuesti",
        emptyFormHeader: "Aitäh, et tulite!",
        emptyFormMessage: "Ups! Paistab, et see vorm on praegu tühi, ilma küsimuste või väljadeta. Vabandame ebamugavuste pärast. Palun võtke ühendust selle vormi loojaga ja teavitage teda probleemist. Aitäh mõistmise ja koostöö eest!",

        restoreFormHeader: "Tundub, et teil on vorm pooleli!",
        restoreFormMessage: "Kas soovite jätkata sealt, kus pooleli jäi?",
        restoreFormButton: "Jätka vormi",
        restoreFormButtonNew: "Alusta uuesti",

        audioPermissionHeader: "Kas soovite vastata häälega?",
        audioPermissionMessage: "Rääkimine muudab vastamise kiireks ja lihtsaks.",
        audioPermissionAcceptButton: "Jah, soovin",
        audioPermissionDenyButton: "Ei, aitäh",
        audioPermissionErrorHeader: "Mikrofon pole saadaval",
        audioPermissionErrorMessage: "Kontrollige brauseri lubasid, et võimaldada juurdepääs mikrofonile.",
        audioPermissionErrorButton: "Okei",
        audioRecorderFailedUpload: "Salvestuse üleslaadimine ebaõnnestus",
        silenceDetectorError: "Me ei kuule teid. Kontrollige mikrofoni seadeid. Vihje: võib-olla kasutate välist mikrofoni.",
        audioRecorderButtonRecord: "Alusta salvestamist",
        audioRecorderButtonRecordMore: "Salvesta veel",
        voiceResponseSelectionMessage: "Valige, kuidas soovite vastata...",
        voiceResponseSelectionOrDivider: "või",
        voiceResponseTextInputPlaceholder: "Sisestage vastus",
        voiceResponseTextInputCharacterCounterMinMessage: "Sisestage vähemalt {min} märki",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} märki (minimaalselt {min} märki)",
        voiceResponseTextInputCharacterValidationMessage: "{count} märki (minimaalselt {min} märki)",

        // checkbox
        checkboxValidationTooFew: "Peate valima vähemalt {min} valikut",
        checkboxValidationTooMany: "Valida võib maksimaalselt {max} valikut",
        checkboxNoneOfTheAboveOption: "Mitte ükski ülaltoodutest",
        checkboxInvalidAnswer: "Vigane vastus.",

        // datepicker
        datePickerPlaceholder: "Valige kuupäev",

        // dropdown
        // email
        emailLabel: "E-post",
        emailInvalid: "Vigane e-post.",
        // file-upload
        fileUploadPluginNameCamera: "Kaamera",
        fileUploadPluginNameCameraVideo: "Salvesta video",
        fileUploadPluginScreenCast: "Ekraanisalvestus",
        fileUploadVideoImportFilesDefault: 'Salvestage või laadige video üles:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Valige video salvestamise võimalus:',
        fileUploadVideoImportFilesNoCamera: 'Vajutage nuppu ekraanivideo salvestamiseks',
        fileUploadVideoImportFilesNoScreenCast: 'Vajutage nuppu video salvestamiseks',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Pole saadaval ühtegi võimalust video salvestamiseks',

        // matrix
        matrixValidationMessage: "Vastus on kehtiv.",
        matrixRow: "Rida",
        matrixColumn: "Veerg",
        matrixRowRequired: "Rida {rowTitle} on kohustuslik.",
        matrixRadioGroup: "Raadiogrupp",
        matrixCheckboxGroup: "Märkeruutude grupp",
        matrixGroupRequired: "Reale {rowTitle}. {type} {groupTitle} on kohustuslik.",
        matrixColumnRequired: "Reale {rowTitle}. Veerg {columnTitle} on kohustuslik.",
        matrixColumnInvalid: "Reale {rowTitle}. Veerg {columnTitle} on vigane.",
        matrixRequired: "Kohustuslik.",
        matrixNumericMustBeNumber: "Peab olema number.",
        matrixNumericMustBeGreaterThenMin: "Peab olema suurem kui {min}.",
        matrixNumericMustBeLessThenMax: "Peab olema väiksem kui {max}.",
        matrixNumericMustBeInteger: "Peab olema täisarv.",
        matrixNumericInvalidNumber: "Vigane number.",

        // name
        nameLabel: "Nimi",
        nameInvalid: "Sisestage ees- ja perekonnanimi.",

        // nps
        npsNotLikely: "Üldse mitte tõenäoline",
        npsExtremelyLikely: "Väga tõenäoline",

        // numeric input
        numericInputRequired: "Kohustuslik.",
        numericInputMustBeNumber: "Peab olema number.",
        numericInputMustBeGreaterThenMin: "Peab olema suurem kui {min}.",
        numericInputMustBeLessThenMax: "Peab olema väiksem kui {max}.",
        numericInputMustBeInteger: "Peab olema täisarv.",
        numericInputInvalidNumber: "Vigane number.",
        numericInputPlaceholder: "Sisestage number",

        // phone
        phoneInvalid: "Telefoninumber on vigane.",
        phoneCountrySelectorLabel: 'Riigi kood',
        phoneCountrySelectorError: 'Valige riik',
        phoneNumberLabel: 'Telefoninumber',
        phoneExample: 'Näide:',

        // boolean
        booleanYesLabel: "Jah",
        booleanNoLabel: "Ei",

        //questionStep
        questionStepAudioQuestionLabel: "Heliküsimus",

        // errors
        invalidPhoneNumber: "{phone} on vigane telefoninumber.",
        invalidEmail: "{email} on vigane e-posti aadress.",
        questionIsRequired: "Küsimus on kohustuslik.",
        answerIsNotValid: "Vastus ei ole kehtiv.",
        unfinishedProbingDialogError: "Lõpetage dialoog.",
        cannotResumePartialResponse: "Ei saa jätkata osalist vastust.",
        failedToSubmitForm: "Vormi esitamine ebaõnnestus. Kontrollige internetiühendust ja proovige uuesti."
    }
}
